import HeaderContent from '../HeaderContent'
import React from 'react'
import '../../App.scss';


function Home() {
    return (
        <>
            <HeaderContent />
        </>
    )
}

export default Home;
